import React from "react";
import Fade from "react-reveal/Fade";
// import speakers1 from "../../assets/png/speakers1.png";
import shedule1 from "../../assets/png/shedule1.png";
import "./Speakers.css";

const Speakers = () => {
   return (
      <div className='speakers' id='speakers'>
         <div className='speakers-main'>
            <div className='speakers-container'>
               <div className='faqHeader'>
                  <h1>Speakers & Judges</h1>
                  <div className='line'></div>
               </div>
               {/* <img src={speakers1} className='speakers-img-1' alt='' /> */}
               <div className='speakers-wrapper'>
                  <div className='speakers-container-row'>
                     <Fade bottom>
                        <p className='coming-soon'>Coming soon</p>
                     </Fade>
                  </div>
               </div>
            </div>
         </div>
         <img src={shedule1} className='about-img' alt='' />
      </div>
   );
};

export default Speakers;
