import React from "react";
import Fade from "react-reveal/Fade";

import "./About.css";
import Ethereum1 from "../../assets/png/Ethereum1.png";
import Ethereum2 from "../../assets/png/Ethereum2.png";

const About = () => {
   // const submitHandler = () => {
   //    console.log("clicked");
   // };

   return (
      <div className='about' id='about'>
         <div className='about-main'>
            <div className='aboutcontainer'>
               <div className='aboutheader'>
                  <h1>About </h1>
                  <div className='line'></div>
               </div>
               <img src={Ethereum2} alt='' className='ethereum2 rotating' />
               <div className='aboutContent'>
                  <Fade bottom>
                     <p>Welcome young warriors to Excel’s first ever Blockchain-hackathon, Chain Wars! The empire of DeFi is desperately calling out for a new hope. Form a team, choose a problem, code your solution and present your amazing ideas to win exciting prizes and claim a bounty.</p>
                     <br />
                     <p>So, what are you waiting for? Register before 20th November 2021 and start coding away your solutions and fork your way through the challenges in front. May the force always be with you.</p>
                     <br />
                     {/* <div className='btn-container'>
                        <button className='btn' onClick={() => submitHandler}>
                           Sign up for summit
                        </button>
                     </div>{" "} */}
                  </Fade>
               </div>
               <img src={Ethereum1} alt='' className='Ethereum1 rotating' />
            </div>
         </div>
      </div>
   );
};
export default About;
