import React from "react";
import Fade from "react-reveal/Fade";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { HiPlus, HiMinusSm } from "react-icons/hi";

import "./Faq.css";

const Faqs = [
   {
      id: 1,
      panel: "label1",
      title: "Does Chain Wars cost money?",
      desc: "Absolutely not, it is free for everyone. The only thing you are required to have is a mind to have  fun, learn new things, and a passion to create new things!",
   },
   {
      id: 2,
      panel: "label2",
      title: "I’m a newbie, so can I participate?",
      desc: "Our events are beginner friendly and hence are open to both beginners and experts alike. Whether you are someone who is absolutely clueless about the world of DeFi and wants to dive right in or whether you are someone who wants to prove their mettle, we encourage all sorts of entries!",
   },
   {
      id: 3,
      panel: "label3",
      title: "I have no Team, and want to register Solo. Can I register for the Chain Wars?",
      desc: "We don’t accept individual entries, and it is mandatory to have a team with a minimum of 2 and a maximum of 5 members. Learning new things is so much more fun, if you have a group of people to learn it with!",
   },
   // {
   //    id: 4,
   //    panel: "label4",
   //    title: "Is there a Code of Conduct?",
   //    desc: "Yes, we have typed together a code of conduct and attached it here. ",
   // },
];

function Faq() {
   const [expanded, setExpanded] = React.useState(false);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   return (
      <div className='faq' id='faq'>
         <div className='faqContainer'>
            <div className='faqHeader'>
               <h1>FAQs</h1>
               <div className='line'></div>
            </div>
            <div className='faqs'>
               <div className='faqsList'>
                  {Faqs.map((faq) => (
                     <Accordion expanded={expanded === `${faq.panel}`} onChange={handleChange(`${faq.panel}`)} className={`${faq.panel}`} key={faq.id}>
                        <Fade bottom>
                           <AccordionSummary expandIcon={expanded === `${faq.panel}` ? <HiMinusSm className='acc--icon  bg-acc' /> : <HiPlus className='acc--icon' />} aria-controls={`${faq.panel}a-content`} id={`${faq.panel}a-header`}>
                              <h3 className='faq--header'>{faq.title}</h3>
                           </AccordionSummary>
                        </Fade>
                        <AccordionDetails>
                           <div className='singleFAQ'>
                              <p>{faq.desc}</p>
                           </div>
                        </AccordionDetails>
                     </Accordion>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}

export default Faq;
