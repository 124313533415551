import React from "react";
import { AiOutlineInstagram, AiFillLinkedin, AiOutlineTwitter, AiFillFacebook } from "react-icons/ai";
import "./Footer.css";
import excelLogo from "../../assets/svg/excelLogo.svg";

const Footer = () => {
   return (
      <div className='footer'>
         <div className='footer-container'>
            <div className='desktop-view'>
               <div className='footer-column'>
                  <div className='logo'>
                     <a href='https://www.excelmec.org/'>
                        <img src={excelLogo} alt='' />
                     </a>
                  </div>
                  <div className='footer-text'>
                     <span> Excel 2021</span> <br />
                     Govt. Model Engineering College
                     <br />
                     Thrikkakara, Kochi, Kerala 682021
                     <br />
                     excel@mec.ac.in
                  </div>
                  <div className='footer-icons'>
                     <a href='https://www.instagram.com/excelmec'>
                        <AiOutlineInstagram className='icon-spec' />
                     </a>
                     <a href='https://www.linkedin.com/company/excelmec'>
                        <AiFillLinkedin className='icon-spec' />
                     </a>
                     <a href='https://twitter.com/excelmec'>
                        <AiOutlineTwitter className='icon-spec' />
                     </a>
                     <a href='https://www.facebook.com/excelmec'>
                        <AiFillFacebook className='icon-spec' />
                     </a>
                  </div>
               </div>
            </div>

            <div className='mobile-view'>
               <div className='footer-column'>
                  <div className='logo'>
                     <a href='https://www.excelmec.org/'>
                        <img src={excelLogo} alt='' />
                     </a>
                     <div className='footer-icons'>
                        <a href='https://www.instagram.com/excelmec'>
                           <AiOutlineInstagram className='icon-spec' />
                        </a>
                        <a href='https://www.linkedin.com/company/excelmec'>
                           <AiFillLinkedin className='icon-spec' />
                        </a>
                        <a href='https://twitter.com/excelmec'>
                           <AiOutlineTwitter className='icon-spec' />
                        </a>
                        <a href='https://www.facebook.com/excelmec'>
                           <AiFillFacebook className='icon-spec' />
                        </a>
                     </div>
                  </div>
                  <div className='footer-text'>
                     <span> Excel 2021</span> <br />
                     Govt. Model Engineering College
                     <br />
                     Thrikkakara, Kochi, Kerala 682021
                     <br />
                     excel@mec.ac.in
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default Footer;
