import React from "react";
import { Landing, Faq, Speakers, About, Navbar, Footer } from "../../components";

function Main() {
   return (
      <>
         <Navbar />
         <Landing />
         <About />
         <Speakers />
         <Faq />
         <Footer />
      </>
   );
}

export default Main;
