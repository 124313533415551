import React, { useState } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

import "./Navbar.css";

import excelLogo from "../../assets/svg/excelLogo.svg";
import chainwarsLogo from "../../assets/svg/chainwars_purple_logo.svg";

function Navbar() {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navActiveStyle = {
    borderBottom: "0.235rem solid #FFB85F",
    marginTop: "0.60rem",
    paddingBottom: "0.35rem",
  };

  const useStyles = makeStyles((t) => ({
    MuiDrawer: {
      padding: "0em 1.8em",
      width: "12em",
      fontFamily: "Montserrat",
      fontStyle: " normal",
      fontWeight: " normal",
      fontSize: " 24px",
      overflow: "hidden",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      background: "var(--primary-color)",
    },
    closebtnIcon: {
      fontSize: "2rem",
      fontWeight: "bold",
      cursor: "pointer",
      position: "absolute",
      right: 20,
      top: 20,
      color: "var(--text)",
    },
  }));

  const classes = useStyles();

  return (
    <div className="navbar">
      <div className="nav-container">
        {" "}
        <a href="http://chainwars.excelmec.org/">
          <div className="nav-left">
            <img src={chainwarsLogo} alt="" className="img-fluid" />
          </div>
        </a>
        <div className="nav-center">
          <Link
            activeStyle={navActiveStyle}
            to="/#home"
            smooth={true}
            duration={2000}
            className="nav-item"
          >
            Home
          </Link>
          <Link
            activeStyle={navActiveStyle}
            to="/#about"
            smooth={true}
            duration={2000}
            className="nav-item"
          >
            About
          </Link>
          {/* <Link activeStyle={navActiveStyle} to='/#schedule' smooth={true} duration={2000} className='nav-item'>
                  Schedule
               </Link> */}
          <Link
            activeStyle={navActiveStyle}
            to="/#speakers"
            smooth={true}
            duration={2000}
            className="nav-item"
          >
            Speakers
          </Link>

          <Link
            activeStyle={navActiveStyle}
            to="/#faq"
            smooth={true}
            duration={2000}
            className="nav-item"
          >
            FAQ
          </Link>
        </div>
        <a href="https://www.excelmec.org/">
          <div className="nav-right">
            <img src={excelLogo} alt="" />
            {/* <div className='navRight-content'>
                  <h3>official</h3>
                  <h5>event</h5>
               </div> */}
          </div>
        </a>
        <div className="nav__drawer">
          <HiOutlineMenuAlt3
            className="nav_open_icon"
            onClick={handleDrawerOpen}
          />
        </div>
        <Drawer
          variant="temporary"
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleDrawerClose();
            } else if (reason !== "escapeKeyDown") {
              handleDrawerClose();
            }
          }}
          anchor="right"
          open={open}
          classes={{ paper: classes.MuiDrawer }}
          className="drawer"
          disableScrollLock={true}
        >
          <div className="div-closebtn">
            <CloseIcon
              onClick={handleDrawerClose}
              className={classes.closebtnIcon}
            />
          </div>
          <br />

          <div onClick={handleDrawerClose}>
            <div className="drawer__container">
              <Link
                to="/#home"
                smooth={true}
                duration={2000}
                className="drawer__link"
              >
                Home
              </Link>

              <Link
                to="/#about"
                smooth={true}
                duration={2000}
                className="drawer__link"
              >
                About
              </Link>

              {/* <Link to="/#schedule" smooth={true} duration={2000} className="drawer__link">
                        Schedule
                     </Link> */}

              <Link
                to="/#speakers"
                smooth={true}
                duration={2000}
                className="drawer__link"
              >
                Speakers
              </Link>

              <Link
                to="/#faq"
                smooth={true}
                duration={2000}
                className="drawer__link"
              >
                FAQ
              </Link>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default Navbar;
