import React, { useEffect, useState } from "react";
// import { Button } from "@material-ui/core";

import globe from "../../assets/png/globe.png";
import "./Landing.css";

const calculateTimeLeft = () => {
   let difference = +new Date(`11/20/2021`) - +new Date(); // MM/DD/YYYY
   let timeLeft = {};

   if (difference > 0) {
      timeLeft = {
         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
         minutes: Math.floor((difference / 1000 / 60) % 60),
         seconds: Math.floor((difference / 1000) % 60),
      };
   }

   return timeLeft;
};

function Landing() {
   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

   useEffect(() => {
      const timer = setTimeout(() => {
         setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
   });

   return (
      <div className='landing' id='home'>
         <img src={globe} alt='' className='globe' />
         <div className='landing--container'>
            <div className='landing--content fadeIn'>
               <h1>
                  Decentralize
                  <br /> the world
               </h1>
               <p>Join us with your stellar teams to explore the ethereal avenues bit by bit and mine your crypto-creativity. Tether your forces and sign up with us.</p>
//                <h3>Nov 20th - Dec 11th 2021</h3>

               {/* <Button>Sign up for summit</Button> */}
            </div>
         </div>
         <div className='landing--countdown'>
            <div className='countdown'>
               <h2>{timeLeft.days}</h2>
               <h4>DAYS</h4>
            </div>
            <div className='countdown'>
               <h2>{timeLeft.hours}</h2>
               <h4>HOURS</h4>
            </div>
            <div className='countdown'>
               <h2>{timeLeft.minutes}</h2>
               <h4>MINUTES</h4>
            </div>
            <div className='countdown'>
               <h2>{timeLeft.seconds}</h2>
               <h4>SECONDS</h4>
            </div>
         </div>
      </div>
   );
}

export default Landing;
