import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { Main } from './pages'
import { BackToTop } from './components'
import './App.css';

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Main />
          </Route>

          <Redirect to='/'/>
        </Switch>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
